<mat-carousel
  [slides]="slides.length"
  [maintainAspectRatio]="false"
  maxWidth="100%"
  [autoplay]="false"
  [hideArrows]="false"
  [hideIndicators]="true"
  [useKeyboard]="true"
  [useMouseWheel]="false"
>
  <mat-carousel-slide
    #matCarouselSlide
    *ngFor="let slide of slides"
    overlayColor="#00000040"
    [hideOverlay]="false"
  >
    <div
      class="knk-slider__slide"
      (click)="onSlideClick(slide)"
      [ngStyle]="{
        'background-image': 'url(' + slide.imageUrl + ')',
        'background-size': openedFullscreen ? 'contain' : 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center'
      }"
    ></div>
  </mat-carousel-slide>
</mat-carousel>
