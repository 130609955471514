/* eslint-disable prettier/prettier */
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  inject
} from '@angular/core';
import { take } from 'rxjs';
import { Slide } from '.';
import { DialogComponent, DialogService } from '../dialog';

@Component({
  selector: 'knk-slider',
  templateUrl: 'slider.component.html',
  styleUrls: ['slider.component.scss']
})
export class SliderComponent extends DialogComponent<void> {
  private readonly dialog = inject(DialogService);

  @Input() slides: Slide[] = [];
  @Input() enableFullscreen = false;

  @Output() slideClick = new EventEmitter<Slide>();

  @HostBinding('style.width') widthHostBinding = '100%';
  @HostBinding('style.height') heightHostBinding = '100%';

  openedFullscreen = false;

  onSlideClick(slide: Slide): void {
    if (this.enableFullscreen) {
      const dialog = this.dialog.openComponentFullScreen(SliderComponent);
      dialog.componentInstance.enableFullscreen = false;
      dialog.componentInstance.slides = this.slides;
      dialog.componentInstance.openedFullscreen = true;
      dialog.componentInstance.slideClick
        .pipe(take(1))
        .subscribe(() => dialog.close());
    }
    this.slideClick.emit(slide);
  }
}
