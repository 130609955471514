<ng-template>
  <div class="carousel-slide" [style.background-image]="image">
    <div class="carousel-slide-content"><ng-content></ng-content></div>
    <div
      *ngIf="!hideOverlay"
      class="carousel-slide-overlay"
      [style.background-color]="overlayColor"
    ></div>
  </div>
</ng-template>
