import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslatePipe } from '@konnektu/helpers';
import { IconComponent, iconChevronLeft, provideIcons } from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../button';
import { StepDirective, StepperComponent } from './stepper.component';

@NgModule({
  declarations: [StepperComponent, StepDirective],
  imports: [
    CommonModule,
    TranslatePipe,
    ButtonModule,
    IconComponent,
    TranslateModule
  ],
  exports: [StepperComponent, StepDirective],
  providers: [provideIcons([iconChevronLeft])]
})
export class StepperModule {}
