import { Directive, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

const NO_DATA_PROVIDER_ERROR = 'Directive expects to recieve data provider';

export interface DataProvider<T> {
  connect(): Observable<T>;
  dispose(): void;
}

@Directive({
  selector: '[knkDataProvider]',
  standalone: true
})
export class DataProviderDirective<T> implements OnDestroy {
  @Input('knkDataProvider') protected dataProvider!: DataProvider<T>;

  protected dataStream$: Observable<T> | null = null;

  get data$() {
    if (this.dataStream$ === null) {
      this.dataStream$ = this.dataProvider.connect();
      return this.dataStream$;
    }
    return this.dataStream$;
  }

  constructor() {
    if (!this.dataProvider) {
      throw new Error(NO_DATA_PROVIDER_ERROR);
    }
  }

  ngOnDestroy(): void {
    this.dataProvider.dispose();
  }
}
