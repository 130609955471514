<div class="knk-file-loader">
  <div class="knk-file-loader__content">
    <div class="knk-file-loader__content__top-panel">
      <knk-icon
        key="upload_file"
        (click)="uploadFile()"
        class="cursor-pointer"
      ></knk-icon>

      <knk-icon
        key="close"
        (click)="clearUploadedFile()"
        *ngIf="selectedFile"
        class="cursor-pointer"
      ></knk-icon>

      <span class="knk-file-loader__content__section-text">
        {{ selectedFile.value?.name ?? 'FileNotSelected' | translate }}
      </span>
    </div>

    <div *ngIf="imageURL" class="knk-file-loader__content__image-preview">
      <img
        [ngClass]="{ fullscreen: isOpenOnFullScreen }"
        (click)="toggleImageSize()"
        [src]="imageURL"
      />
    </div>
  </div>
</div>
