import {
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[knkStep]'
})
export class StepDirective {
  @Input() title: string | undefined;
  @Input() leftSideHeader: string | undefined;
  @Input() leftSideSubHeader: string | undefined;
  @Input() leftSideFooter: string | undefined;
  @Input() loading = false;
  @Input() leftSideTemplate: TemplateRef<any> | undefined;
  @Input() formGroupToValidate: AbstractControl | undefined;
  @Input() enabled = true;
  @Input() isNextButtonDisabled = false;

  constructor(public content: TemplateRef<any>) {}
}

@Component({
  selector: 'knk-stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['stepper.component.scss']
})
export class StepperComponent {
  @Input() currentStep = 0;

  @ContentChildren(StepDirective, { descendants: true })
  steps!: QueryList<StepDirective>;

  @Output() doneClick: EventEmitter<void> = new EventEmitter<void>();

  @Output() cancelClick: EventEmitter<void> = new EventEmitter<void>();

  @Output() stepChanged: EventEmitter<number> = new EventEmitter<number>();

  get currentStepDirective(): StepDirective {
    return this.steps.toArray()[this.currentStep];
  }

  goToStep(index: number): void {
    if (!this.currentStepValid) {
      return;
    }
    this.currentStep = index;
    this.stepChanged.emit(this.currentStep);
  }

  isLastStep(index: number): boolean {
    return this.steps.length - 1 === index;
  }

  nextStep(): void {
    if (this.isLastStep(this.currentStep)) {
      this.done();
    } else {
      this.currentStep++;
      this.stepChanged.emit(this.currentStep);
    }
  }

  done(): void {
    this.doneClick.emit();
  }

  previousStep(): void {
    this.currentStep--;
    this.stepChanged.emit(this.currentStep);
  }

  get currentStepValid(): boolean {
    if (this.steps.toArray()[this.currentStep]?.formGroupToValidate) {
      return (
        this.steps.toArray()[this.currentStep].formGroupToValidate?.valid ||
        true
      );
    } else {
      return true;
    }
  }

  get isCurrentStepTwoSide(): boolean {
    return (
      !!this.currentStepDirective?.leftSideHeader ||
      !!this.currentStepDirective?.leftSideSubHeader ||
      !!this.currentStepDirective?.leftSideFooter ||
      !!this.currentStepDirective.leftSideTemplate
    );
  }
}
