import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Provider,
  forwardRef
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import { TranslatePipe } from '@konnektu/helpers';
import {
  IconComponent,
  iconClose,
  iconUploadFile,
  provideIcons
} from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import { ControlValueAccessor } from '../abstractions';

const FILE_INPUT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileLoaderComponent),
  multi: true
};

@Component({
  selector: 'knk-file-loader',
  templateUrl: 'file-loader.component.html',
  styleUrls: ['file-loader.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    TranslatePipe,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    TranslateModule
  ],
  providers: [
    FILE_INPUT_VALUE_ACCESSOR,
    provideIcons([iconUploadFile, iconClose])
  ]
})
export class FileLoaderComponent extends ControlValueAccessor<
  File | string | null
> {
  @Input() availableContentType: string[] = [];

  @Output() fileSelect = new EventEmitter<File>();

  selectedFile = new FormControl<File | null>(null);

  isOpenOnFullScreen = false;

  imageURL = '';

  uploadFile() {
    const input = document.createElement('input');

    input.type = 'file';
    input.multiple = false;

    if (this.availableContentType.length) {
      input.accept = this.availableContentType.join(',');
    }

    input.onchange = (ev: Event) => {
      const { files } = ev.target as HTMLInputElement;

      if (files?.length) {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(files[0]);

        fileReader.onload = (e) => {
          this.imageURL = e.target?.result as string;
          this.selectedFile.setValue(files[0]);
          this.handleFileSelect();
        };
      }
    };
    input.click();
  }

  clearUploadedFile() {
    this.selectedFile.reset();
    this.imageURL = '';
  }

  protected handleFileSelect() {
    const file = this.selectedFile.getRawValue();
    if (file) {
      this.onChanged(file);
      this.onTouched();
      this.fileSelect.emit(file);
    }
  }

  writeValue(value: File | string): void {
    if (typeof value === 'string') {
      this.imageURL = value;
    } else {
      this.selectedFile.setValue(value);
    }
  }

  toggleImageSize() {
    this.isOpenOnFullScreen = !this.isOpenOnFullScreen;
  }
}
