<div class="knk-stepper" *ngIf="steps?.length">
  <div class="knk-stepper__header">
    <knk-button (clicked)="previousStep()" *ngIf="currentStep > 0">
      <knk-icon key="chevron_left" size="lg"></knk-icon>
    </knk-button>

    <div class="knk-stepper__header__steps">
      <span
        *ngFor="let step of steps; let index = index"
        (click)="goToStep(index)"
        class="knk-stepper__header__steps__step"
        [class.current]="index === currentStep"
      >
        {{ step.title }}
        <span *ngIf="!isLastStep(index)">&gt;</span>
      </span>
    </div>

    <div class="knk-stepper__header__actions">
      <knk-button preset="alert" (clicked)="cancelClick.emit()">
        {{ 'common.cancel' | translate }}
      </knk-button>

      <knk-button
        [disabled]="
          !currentStepValid || currentStepDirective.isNextButtonDisabled
        "
        (clicked)="nextStep()"
      >
        {{
          isLastStep(currentStep)
            ? ('common.done' | translate)
            : ('common.next' | translate)
        }}
      </knk-button>
    </div>
  </div>
  <hr class="knk-stepper__divider" />

  <ng-container *ngIf="isCurrentStepTwoSide">
    <div class="knk-stepper__split-content">
      <div class="knk-stepper__split-content__leftside">
        <ng-container
          *ngIf="
            (currentStepDirective?.leftSideHeader ||
              currentStepDirective?.leftSideSubHeader ||
              currentStepDirective?.leftSideFooter) &&
            !currentStepDirective.leftSideTemplate
          "
        >
          <h1>{{ currentStepDirective.leftSideHeader }}</h1>

          <h4>{{ currentStepDirective.leftSideSubHeader }}</h4>

          <span>{{ currentStepDirective.leftSideFooter }}</span>
        </ng-container>

        <ng-container
          *ngIf="
            !(
              currentStepDirective?.leftSideHeader ||
              currentStepDirective?.leftSideSubHeader ||
              currentStepDirective?.leftSideFooter
            ) && !!currentStepDirective.leftSideTemplate
          "
        >
          <ng-container
            *ngTemplateOutlet="currentStepDirective.leftSideTemplate"
          ></ng-container>
        </ng-container>
      </div>

      <div class="knk-stepper__split-content__rightside">
        <ng-container
          *ngTemplateOutlet="currentStepDirective.content"
        ></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isCurrentStepTwoSide">
    <div class="knk-stepper__content">
      <ng-container
        *ngTemplateOutlet="currentStepDirective.content"
      ></ng-container>
    </div>
  </ng-container>
</div>
